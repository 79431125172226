@use 'sass:map';
@use '../../ThemeFactory/Tokens/breakpoints' as breakpoints;

@mixin layoutStyles($layoutTokens) {
}

@mixin responsiveStyles($responsiveTokens) {
    & .avatarVideo {
        border-radius: map.get($responsiveTokens, border-radius);
        margin: map.get($responsiveTokens, margin);
        top: map.get($responsiveTokens, top);

        @if (map.get($responsiveTokens, square)) {
            aspect-ratio: 1;
            height: auto;
            width: map.get($responsiveTokens, square);
        } @else {
            height: map.get($responsiveTokens, height);
            width: map.get($responsiveTokens, width);
        }
    }

    & .videoContainerOverlay {
        opacity: if(map.get($responsiveTokens, blur) == true, 1, 0);
    }
}

@mixin stateStyles($stateTokens) {
}

@mixin BuildStyles($thisComponentTokens) {
    & .avatarVideo {
        position: fixed;
        width: map.get($thisComponentTokens, width);
        height: map.get($thisComponentTokens, height);
        top: map.get($thisComponentTokens, top);
        left: map.get($thisComponentTokens, left);
        z-index: 1;

        @if (map.get($thisComponentTokens, miniature)) {
            margin: map.get($thisComponentTokens, margin);
            box-shadow: map.get($thisComponentTokens, shadow);
            cursor: pointer;
            border-radius: 50%;
            z-index: 99;
        }

        @if (map.get($thisComponentTokens, direction)) {
            transform: translateX(map.get($thisComponentTokens, direction));
            transition-duration: 0.5s;
            transition-property: transform, width, border-radius, height;
            transition-timing-function: ease-in-out;
        }

        &::before,
        &::after {
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            width: 100%;
        }

        &::before {
            @if (map.get($thisComponentTokens, leftBackground)) {
                background: map.get($thisComponentTokens, leftBackground);
            }

            left: calc(-100% + 1px);
        }

        &::after {
            @if (map.get($thisComponentTokens, rightBackground)) {
                background: map.get($thisComponentTokens, rightBackground);
            }

            left: calc(100%);
        }
    }

    // show overlay if blur is true

    & .videoContainerOverlay {
        width: 100vw;
        height: 100vh;
        position: fixed;
        background-color: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(14px) opacity(91%);
        z-index: 2;
        top: 0;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        @if (map.get($thisComponentTokens, blur)) {
            opacity: 100%;
        }
    }

    @if map.get($thisComponentTokens, direction) != 0 {
        & .seamless {
            position: absolute;
            height: 100dvh;
            transform: translateX(map.get($thisComponentTokens, direction));
            transition: 1s;
            width: 30dvw; /* About Half the size of the Extra layout shift */
            right: calc((map.get($thisComponentTokens, direction) * -1) + -20dvw); /* Half the width */
            z-index: 2;
            background: linear-gradient(0deg, #c8cac1 0%, #c4c7c0 100%); /* These values are to be defined per case */
            mask: linear-gradient(90deg, transparent 0%, rgb(0, 0, 0) 50%, transparent 100%);
            -webkit-mask: linear-gradient(90deg, transparent 0%, rgb(0, 0, 0) 50%, transparent 100%);
        }
    }

    & .localVideo {
        display: none;
    }
}

@mixin BuildClass($themeName, $specificName, $thisComponentTokens) {
    $className: videoContainer;

    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include BuildStyles($thisComponentTokens);
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // DO NOT EDIT BELOW THIS LINE
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    @include build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens);
}

// Build states from states properties
@mixin build_States($map) {
    @if map.has-key($map, states) {
        @debug ('- states found!');
        @each $stateName, $stateTokens in map.get($map, states) {
            &.#{$stateName} {
                @debug ('      -> Generated &.' + $stateName);
                @include stateStyles($stateTokens);
            }
            @if ($stateName ==hover) {
                &:hover {
                    @include stateStyles($stateTokens);
                }
            }
        }
    }
}

// Build responsiveness from breakpoints properties
@mixin build_Breakpoints_And_States($map, $orientationName) {
    // Other properties than breakpoints
    @include build_States($map);
    // Breakpoints
    @if map.has-key($map, breakpoints) {
        @debug ('- breakpoints found!');
        @each $breakpointName, $breakpointTokens in map.get($map, breakpoints) {
            @debug ('   - ' + $breakpointName);
            // No states
            @include breakpoints.responsive($orientationName, $breakpointName) {
                @include responsiveStyles($breakpointTokens);
            }
            // States within breakpoints
            @include build_States($breakpointTokens);
        }
    }
}

// Build responsiveness from orientations properties (and their breakpoints)
@mixin build_Responsiveness_And_States($map) {
    // Other properties than Orientations
    @include build_Breakpoints_And_States($map, allOrientations);
    // Orientations
    @if map.has-key($map, orientations) {
        @debug ('- orientations found!');
        @each $orientationName, $orientationTokens in map.get($map, orientations) {
            @debug ('   - ' + $orientationName);
            // No breakpoints
            @include breakpoints.responsive($orientationName, extraSmall) {
                @include responsiveStyles($orientationTokens);
            }
            // Breakpoints within orientations
            @include build_Breakpoints_And_States($orientationTokens, $orientationName);
        }
    } @else {
        @debug ('- no orientation found.');
    }
}

// Build layouts from layouts properties (and their responsiveness)
@mixin build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens) {
    @debug ('Building  ' + $className + ':');
    // Other properties than layout
    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include build_Responsiveness_And_States($thisComponentTokens);
    }
    // Layouts
    @if map.has-key($thisComponentTokens, layouts) {
        @debug ('- layouts found!');
        @each $layoutName, $layoutTokens in map.get($thisComponentTokens, layouts) {
            @debug ('   - ' + $layoutName);
            .#{$themeName} .#{$layoutName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
                @debug ('      -> Generated .' + $layoutName + ' .' + $className + $specificName);
                @include layoutStyles($layoutTokens);
                // Responsiveness within layout
                @include build_Responsiveness_And_States($layoutTokens);
            }
        }
    } @else {
        @debug ('- no layout found.');
    }
}
