@use 'sass:map';
@use '../../ThemeFactory/utils.scss' as *;

@mixin layoutStyles($layoutTokens) {
    display: if(map.get($layoutTokens, visible) == false, none, null);
    @debug ('      -> Provided layoutStyles');
}

@mixin responsiveStyles($responsiveTokens) {
    @debug ('      -> Provided responsiveStyles');
}

@mixin stateStyles($stateTokens) {
    @debug ('      -> Provided stateStyles');
}

@mixin BuildStyles($thisComponentTokens) {
    z-index: 4;
    width: fit-content;
    padding: map.get($thisComponentTokens, padding);
    border-radius: 0;
    // max-width: 66%; Should be set by parent's max-width
    & .label {
        @include displayTextStyle(map.get($thisComponentTokens, textStyle));
        p {
            margin: 0;
        }
        & a[href*='http'] {
            @include displayTextStyle(map.get($thisComponentTokens, link, textStyle));
            color: map.get($thisComponentTokens, link, color) !important;
            &:hover {
                background-color: map.get($thisComponentTokens, link, hover, backgroundColor);
                color: map.get($thisComponentTokens, link, hover, color) !important;
            }
        }
    }
    &.avatar {
        border-bottom-right-radius: map.get($thisComponentTokens, borderRadius);
        justify-self: start;
        justify-content: start;
        text-align: left;
        background-color: map.get($thisComponentTokens, avatar, backgroundColor);
        display: if(map.get($thisComponentTokens, avatar, visible) ==false, none, block);
        margin: map.get($thisComponentTokens, avatar, margin);
        & .label{
            color: map.get($thisComponentTokens, avatar, textColor);
        }
    }
    &.user {
        border-bottom-left-radius: map.get($thisComponentTokens, borderRadius);
        justify-self: end;
        justify-content: end;
        text-align: right;
        background-color: map.get($thisComponentTokens, user, backgroundColor);
        display: if(map.get($thisComponentTokens, visible) == false, none, block);
        margin: map.get($thisComponentTokens, user, margin);
        & .label{
            color: map.get($thisComponentTokens, user, textColor);
        }
    }
    &.media{
        padding:0;
        max-width: 60%;
        display: flex;
        flex-direction: column;
        &:has(.icon){
            background: transparent;
        }
        .bubble{
            width:100%;
            margin: 0;
        }
        .mediaPreview{
            display: flex;
            justify-content: center;
            img{
                max-width: 100%;
            }
            &.icon{
                    margin:1.5rem;
            }
            &.icon, &.horizontal,&.squared{
                &.extreme{
                    img{
                        object-fit: contain;
                    }
                }
                &:not(.extreme){
                    img{
                        height: 2rem;
                        object-fit: cover;
                    }
                }
            }
            &:not(.icon){
                &.vertical{
                        img{
                        object-fit:cover;
                        aspect-ratio: 1/1;
                    }
                }
            }
    }
}
}

@mixin BuildClass($themeName, $specificName, $thisComponentTokens) {
    $className: bubble;
    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include BuildStyles($thisComponentTokens);
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // DO NOT EDIT BELOW THIS LINE
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    @include build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens);
}

// Build states from states properties
@mixin build_States($map) {
    @if map.has-key($map, states) {
        @debug ('- states found!');
        @each $stateName, $stateTokens in map.get($map, states) {
            &.#{$stateName} {
                @debug ('      -> Generated &.' + $stateName);
                @include stateStyles($stateTokens);
            }
        }
    }
}

// Build responsiveness from breakpoints properties
@mixin build_Breakpoints_And_States($map, $orientationName) {
    // Other properties than breakpoints
    @include build_States($map);
    // Breakpoints
    @if map.has-key($map, breakpoints) {
        @debug ('- breakpoints found!');
        @each $breakpointName, $breakpointTokens in map.get($map, breakpoints) {
            @debug ('   - ' + $breakpointName);
            // No states
            @include breakpoints.responsive($orientationName, $breakpointName) {
                @include responsiveStyles($breakpointTokens);
            }
            // States within breakpoints
            @include build_States($breakpointTokens);
        }
    }
}

// Build responsiveness from orientations properties (and their breakpoints)
@mixin build_Responsiveness_And_States($map) {
    // Other properties than Orientations
    @include build_Breakpoints_And_States($map, allOrientations);
    // Orientations
    @if map.has-key($map, orientations) {
        @debug ('- orientations found!');
        @each $orientationName, $orientationTokens in map.get($map, orientations) {
            @debug ('   - ' + $orientationName);
            // No breakpoints
            @include breakpoints.responsive($orientationName, extraSmall) {
                @include responsiveStyles($orientationTokens);
            }
            // Breakpoints within orientations
            @include build_Breakpoints_And_States($orientationTokens, $orientationName);
        }
    } @else {
        @debug ('- no orientation found.');
    }
}

// Build layouts from layouts properties (and their responsiveness)
@mixin build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens) {
    @debug ('Building  ' + $className + ':');
    // Other properties than layout
    .#{$themeName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
        @include build_Responsiveness_And_States($thisComponentTokens);
    }
    // Layouts
    @if map.has-key($thisComponentTokens, layouts) {
        @debug ('- layouts found!');
        @each $layoutName, $layoutTokens in map.get($thisComponentTokens, layouts) {
            @debug ('   - ' + $layoutName);
            .#{$themeName} .#{$layoutName} .#{$className}#{if($specificName, '.'+$specificName,'')} {
                @debug ('      -> Generated .' + $layoutName + ' .' + $className + $specificName);
                @include layoutStyles($layoutTokens);
                // Responsiveness within layout
                @include build_Responsiveness_And_States($layoutTokens);
            }
        }
    } @else {
        @debug ('- no layout found.');
    }
}
