@use 'sass:map';
@use "../editorialButton.class.scss" as _EditorialButton;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
//
//    IconLabelButton Class
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@mixin layoutStyles($layoutTokens){
    @include _EditorialButton.layoutStyles($layoutTokens);
    @debug("      -> Provided layoutStyles from IconLabelButton");
}

@mixin responsiveStyles($responsiveTokens){
    @include _EditorialButton.responsiveStyles($responsiveTokens);
    @debug("      -> Provided responsiveStyles from IconLabelButton");
}

@mixin stateStyles($stateTokens){
    @include _EditorialButton.stateStyles($stateTokens);
    & a{
        color: map.get($stateTokens, textColor) !important;  
    }
    & .container {
        & .asset {
            fill: map.get($stateTokens, iconColor);
            color: map.get($stateTokens, iconColor);

            & svg {
                fill: map.get($stateTokens, iconColor);
                color: map.get($stateTokens, iconColor);

                & path {
                    fill: map.get($stateTokens, iconColor);
                    color: map.get($stateTokens, iconColor);
                }
            }
        }
    }
    @debug("      -> Provided stateStyles from IconLabelButton");
}

@mixin BuildStyles($thisComponentTokens){
    @include _EditorialButton.BuildStyles($thisComponentTokens);
    & .container .asset {
        fill: map.get($thisComponentTokens, iconColor);
        color: map.get($thisComponentTokens, iconColor);
        width: map.get($thisComponentTokens, iconWidth);
        height: map.get($thisComponentTokens, iconHeight);
        @if (map.has-key($thisComponentTokens, iconHeight)) {
            max-width: calc(2.5 * map.get($thisComponentTokens, iconHeight));
            min-width: map.get($thisComponentTokens, iconHeight);
        }

        & svg {
            color: map.get($thisComponentTokens, iconColor);
            fill: map.get($thisComponentTokens, iconColor);

            & path {
                color: map.get($thisComponentTokens, iconColor);
                fill: map.get($thisComponentTokens, iconColor);
            }
        }
    }
}

@mixin BuildClass(
    $themeName, $specificName, $thisComponentTokens
) {
    $className: iconLabelButton;
    @debug("Building ."+$className+if($specificName, "."+$specificName,""));
	.#{$themeName} .#{$className}#{if($specificName, "."+$specificName,"")}{
        @include BuildStyles($thisComponentTokens);
    }
 
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DO NOT EDIT BELOW THIS LINE
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////











    
@include build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens);
}

// Build states from states properties
@mixin build_States($map){
    @if map.has-key($map, states){
        @debug("- states found!");
        @each $stateName, $stateTokens in map.get($map, states){
            &.#{$stateName}{
                @debug("      -> Generated &."+$stateName);
                @include stateStyles($stateTokens);
            }
            @if($stateName ==hover) {
                &:hover {
                    @include stateStyles($stateTokens);
                }
            }
        }
    }
}

// Build responsiveness from breakpoints properties
@mixin build_Breakpoints_And_States($map, $orientationName){
    // Other properties than breakpoints
        @include build_States($map);
    // Breakpoints
    @if map.has-key($map, breakpoints){
        @debug("- breakpoints found!");
        @each $breakpointName, $breakpointTokens in map.get($map, breakpoints){
            @debug("   - "+$breakpointName);
            // No states
                @include breakpoints.responsive($orientationName, $breakpointName){
                    @include responsiveStyles($breakpointTokens);
                }
            // States within breakpoints
                @include build_States($breakpointTokens);
        }
    }
}

// Build responsiveness from orientations properties (and their breakpoints)
@mixin build_Responsiveness_And_States($map){
    // Other properties than Orientations
        @include build_Breakpoints_And_States($map, allOrientations);
    // Orientations
        @if map.has-key($map, orientations){
            @debug("- orientations found!");
            @each $orientationName, $orientationTokens in map.get($map, orientations){
                @debug("   - "+$orientationName);
                // No breakpoints
                    @include breakpoints.responsive($orientationName, extraSmall){
                        @include responsiveStyles($orientationTokens);
                    }
                // Breakpoints within orientations
                    @include build_Breakpoints_And_States($orientationTokens, $orientationName);
            }
        } @else {
            @debug("- no orientation found.");
        }
}

// Build layouts from layouts properties (and their responsiveness)
@mixin build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens){
    @debug("Building  "+$className+":");
    // Other properties than layout
        .#{$themeName} .#{$className}#{if($specificName, "."+$specificName,"")}{
            @include build_Responsiveness_And_States($thisComponentTokens);
        }

    // Layouts
    @if map.has-key($thisComponentTokens, layouts){
        @debug("- layouts found!");
        @each $layoutName, $layoutTokens in map.get($thisComponentTokens, layouts){
            @debug("   - "+$layoutName);
            .#{$themeName} .#{$layoutName} .#{$className}#{if($specificName, "."+$specificName,"")}{
                @debug("      -> Generated ."+$layoutName+" ."+$className+$specificName);
                @include layoutStyles($layoutTokens);
                // Responsiveness within layout
                @include build_Responsiveness_And_States($layoutTokens);
            }
        }
    } @else {
        @debug("- no layout found.");
    }
}