@use 'sass:map';

@function capitalize($string) {
    @return to-upper-case(str-slice($string, 1, 1)) + str-slice($string, 2);
}

@mixin displayTextStyle($textStyle) {
    // Example of $textStyle
    // (familyWeight: (weight: 400, fontFace: RocheSans, sans-serif), 
    font-size: map.get($textStyle, fontSize);
    line-height: map.get($textStyle, lineHeight);
    font-weight: map.get($textStyle, familyWeight, weight);
    font-family: map.get($textStyle, familyWeight, fontFace);
}