@use 'sass:map';
/* -----------------------------------------
    BLUEPRINT PRIMITIVE BREAKPOINTS

    Use like this:

    @include responsive($orientation, $breakpoint){
        your code here
    }
    $orientation: portrait | landscape | allOrientations
    $breakpoint: extraSmall | small | large | extraLarge
----------------------------------------- */

$minWidths: (
    extraSmall: 0,
    small: 40em, // 768px
    large: 75em, // 1200px
    extraLarge: 112.5em, // 1800px
);

@mixin responsive($orientation, $breakpoint) {
    $minWidth: map.get($minWidths, $breakpoint);

    @if ($orientation != allOrientations) {
        @if ($minWidth > 0) {
            @media only screen and (orientation: $orientation) and (min-width: $minWidth) {
                @content;
            }
        } @else {
            @media only screen and (orientation: $orientation) {
                @content;
            }
        }
    } @else {
        @media only screen and (min-width: $minWidth) {
            @content;
        }
    }
}
