@use 'sass:map';
@use '../../ThemeFactory/Tokens/breakpoints' as breakpoints;
@use "../Button/IconButton/iconButton.class.scss" as _Button;
@mixin layoutStyles($layoutTokens){
      @debug("      -> Provided layoutStyles");
}

@mixin responsiveStyles($responsiveTokens){
     @debug("      -> Provided responsiveStyles");
}

@mixin stateStyles($stateTokens){
      @debug("      -> Provided stateStyles");
}

@mixin BuildStyles($thisComponentTokens){
    &.fullscreen{
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 100;
    }
    .visible{
        display: flex;
    }
    .hidden{
        display: none;
    }
    &.visible{
        video{
         width: 100%;
         height: 100%
        }
        .playerContainer{
            position: relative;
          .overlay{
            width: 100%;
            height: 100%;
            position:absolute;
            top:0;
            left:0;
            background-color: black;
            opacity:0.5;
        }

        > button{
            position: absolute;
            fill: white;
            z-index: 1;
            top: 0;
            left: 0;
        }

        > button, > button *{
            width: 100% !important;
            height: 100% !important;
        }
        }
    }
    .controlsContainer {
        display: flex;
        flex-Direction: row;
        background-color: black;
        width: 100%;
        .controls{
            margin: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            button {
                padding: 0;
            }
            @include _Button.BuildStyles(map.get($thisComponentTokens, controlTokens));
        }
        progress{
            width: 100%;
            align-self: center;
            cursor: pointer;
        }
      }
    
}

@mixin BuildClass(
    $themeName, $specificName, $thisComponentTokens
) {
    $className: videoPlayer; 

	.#{$themeName} .#{$className}#{if($specificName, "."+$specificName,"")}{
        @include BuildStyles($thisComponentTokens); 
	}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DO NOT EDIT BELOW THIS LINE
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    













    @include build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens);
}

// Build states from states properties
@mixin build_States($map){
    @if map.has-key($map, states){
        @debug("- states found!");
        @each $stateName, $stateTokens in map.get($map, states){
            &.#{$stateName}{
                @debug("      -> Generated &."+$stateName);
                @include stateStyles($stateTokens);
            }
            @if($stateName ==hover) {
                &:hover {
                    @include stateStyles($stateTokens);
                }
            }
        }
    }
}

// Build responsiveness from breakpoints properties
@mixin build_Breakpoints_And_States($map, $orientationName){
    // Other properties than breakpoints
        @include build_States($map);
    // Breakpoints
    @if map.has-key($map, breakpoints){
        @debug("- breakpoints found!");
        @each $breakpointName, $breakpointTokens in map.get($map, breakpoints){
            @debug("   - "+$breakpointName);
            // No states
                @include breakpoints.responsive($orientationName, $breakpointName){
                    @include responsiveStyles($breakpointTokens);
                }
            // States within breakpoints
                @include build_States($breakpointTokens);
        }
    }
}

// Build responsiveness from orientations properties (and their breakpoints)
@mixin build_Responsiveness_And_States($map){
    // Other properties than Orientations
        @include build_Breakpoints_And_States($map, allOrientations);
    // Orientations
        @if map.has-key($map, orientations){
            @debug("- orientations found!");
            @each $orientationName, $orientationTokens in map.get($map, orientations){
                @debug("   - "+$orientationName);
                // No breakpoints
                    @include breakpoints.responsive($orientationName, extraSmall){
                        @include responsiveStyles($orientationTokens);
                    }
                // Breakpoints within orientations
                    @include build_Breakpoints_And_States($orientationTokens, $orientationName);
            }
        } @else {
            @debug("- no orientation found.");
        }
}

// Build layouts from layouts properties (and their responsiveness)
@mixin build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens){
    @debug("Building  "+$className+":");
    // Other properties than layout
        .#{$themeName} .#{$className}#{if($specificName, "."+$specificName,"")}{
            @include build_Responsiveness_And_States($thisComponentTokens);
        }
    // Layouts
    @if map.has-key($thisComponentTokens, layouts){
        @debug("- layouts found!");
        @each $layoutName, $layoutTokens in map.get($thisComponentTokens, layouts){
            @debug("   - "+$layoutName);
            .#{$themeName} .#{$layoutName} .#{$className}#{if($specificName, "."+$specificName,"")}{
                @debug("      -> Generated ."+$layoutName+" ."+$className+$specificName);
                @include layoutStyles($layoutTokens);
                // Responsiveness within layout
                @include build_Responsiveness_And_States($layoutTokens);
            }
        }
    } @else {
        @debug("- no layout found.");
    }
}