@use 'sass:map';
@use '../../ThemeFactory/utils.scss' as *;

@mixin layoutStyles($layoutTokens){
    @debug("      -> Provided layoutStyles");
}

@mixin responsiveStyles($responsiveTokens){
    @debug("      -> Provided responsiveStyles");
}

@mixin stateStyles($stateTokens){
    @debug("      -> Provided stateStyles");
}

@mixin BuildStyles($thisComponentTokens){
    padding: map.get($thisComponentTokens, padding);
    position: relative;

    &-controls {
        display: flex;
        justify-content: space-between;
        margin: auto;
    }

    &-control {
        align-items: center;
        display: flex;

        &-chevron {
            cursor: pointer;
        }

        &-middle {
            flex: 1;
            justify-content: center;
            padding: map.get($thisComponentTokens, control, middle, padding);
            width: map.get($thisComponentTokens, control, middle, width);
        }

        & svg,
        & svg * {
            fill: currentColor;
        }
    }

    &-dots {
        display: flex;
        justify-content: center;
        gap: map.get($thisComponentTokens, dots, gap);
        margin: map.get($thisComponentTokens, dots, margin);
    }

    &-dot {
        background-color: map.get($thisComponentTokens, dot, backgroundColor);
        cursor: pointer;
        height: map.get($thisComponentTokens, dot, height);
        width: map.get($thisComponentTokens, dot, width);
        transition: background-color .3s ease-in-out;

        &[data-active], &:hover {
            background-color: map.get($thisComponentTokens, dot, active, backgroundColor);
        }
    }

    &-slides {
        display: flex;
    }

    &-slide {
        flex: 0 0 100%;

        &:not([data-active]) {
            display: none;
        }
    }
}

@mixin BuildClass(
    $themeName, $specificName, $thisComponentTokens
) {
    $className: carousel;
	.#{$themeName} .#{$className}#{if($specificName, "."+$specificName,"")}{
        @include BuildStyles($thisComponentTokens); 
	}



/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DO NOT EDIT BELOW THIS LINE
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    













    @include build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens);
}

// Build states from states properties
@mixin build_States($map){
    @if map.has-key($map, states){
        @debug("- states found!");
        @each $stateName, $stateTokens in map.get($map, states){
            &.#{$stateName}{
                @debug("      -> Generated &."+$stateName);
                @include stateStyles($stateTokens);
            }
        }
    }
}

// Build responsiveness from breakpoints properties
@mixin build_Breakpoints_And_States($map, $orientationName){
    // Other properties than breakpoints
        @include build_States($map);
    // Breakpoints
    @if map.has-key($map, breakpoints){
        @debug("- breakpoints found!");
        @each $breakpointName, $breakpointTokens in map.get($map, breakpoints){
            @debug("   - "+$breakpointName);
            // No states
                @include breakpoints.responsive($orientationName, $breakpointName){
                    @include responsiveStyles($breakpointTokens);
                }
            // States within breakpoints
                @include build_States($breakpointTokens);
        }
    }
}

// Build responsiveness from orientations properties (and their breakpoints)
@mixin build_Responsiveness_And_States($map){
    // Other properties than Orientations
        @include build_Breakpoints_And_States($map, allOrientations);
    // Orientations
        @if map.has-key($map, orientations){
            @debug("- orientations found!");
            @each $orientationName, $orientationTokens in map.get($map, orientations){
                @debug("   - "+$orientationName);
                // No breakpoints
                    @include breakpoints.responsive($orientationName, extraSmall){
                        @include responsiveStyles($orientationTokens);
                    }
                // Breakpoints within orientations
                    @include build_Breakpoints_And_States($orientationTokens, $orientationName);
            }
        } @else {
            @debug("- no orientation found.");
        }
}

// Build layouts from layouts properties (and their responsiveness)
@mixin build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens){
    @debug("Building  "+$className+":");
    // Other properties than layout
        .#{$themeName} .#{$className}#{if($specificName, "."+$specificName,"")}{
            @include build_Responsiveness_And_States($thisComponentTokens);
        }
    // Layouts
    @if map.has-key($thisComponentTokens, layouts){
        @debug("- layouts found!");
        @each $layoutName, $layoutTokens in map.get($thisComponentTokens, layouts){
            @debug("   - "+$layoutName);
            .#{$themeName} .#{$layoutName} .#{$className}#{if($specificName, "."+$specificName,"")}{
                @debug("      -> Generated ."+$layoutName+" ."+$className+$specificName);
                @include layoutStyles($layoutTokens);
                // Responsiveness within layout
                @include build_Responsiveness_And_States($layoutTokens);
            }
        }
    } @else {
        @debug("- no layout found.");
    }
}