@use 'sass:map';
@use '../../ThemeFactory/utils.scss' as *;

@mixin layoutStyles($layoutTokens){
    @debug("      -> Provided layoutStyles");
}

@mixin responsiveStyles($responsiveTokens){
    @debug("      -> Provided responsiveStyles");
}

@mixin stateStyles($stateTokens){
    @debug("      -> Provided stateStyles");
}

@mixin BuildStyles($thisComponentTokens){
display: inline-flex;
position: relative;
z-index: 2;

&.centery {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

&-layer {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 3;
}

&-trigger {
    & svg,
    & svg * {
        fill: map.get($thisComponentTokens, fontColor);
    }
    cursor: pointer;
}

&-container {
    background: map.get($thisComponentTokens, backgroundColor);
    display: none;
    position: absolute;
    width: max-content;
    max-width: 100vw;
}

&-content {
    padding: 1.5rem;
}

&[data-opened] {
    z-index: 4;

    & > .dropdown-trigger {
        @if (map.get($thisComponentTokens, border, bottom)) {
            position: relative;

            &::after {
                background-color: map.get($thisComponentTokens, border, bottom, color);
                bottom: 0;
                content: '';
                height: map.get($thisComponentTokens, border, bottom, width);
                left: 0;
                position: absolute;
                right: 0;
            }
        }

        & svg,
        & svg * {
            fill: map.get($thisComponentTokens, fontColor);
        }
    }

    & > .dropdown-container {
        background: map.get($thisComponentTokens, backgroundColor);
        display: block;
    }
}

&[data-alignment='top-left'] {
    & > .dropdown-container {
        bottom: 100%;
        right: 0;
    }
}

&[data-alignment='top-right'] {
    & > .dropdown-container {
        bottom: 100%;
        left: 0;
    }
}

&[data-alignment='bottom-left'] {
    & > .dropdown-container {
        right: 0;
        top: 100%;
    }
}

&[data-alignment='bottom-right'] {
    & > .dropdown-container {
        left: 0;
        top: 100%;
    }
}

&-topbar {
    & .left {
        justify-content: space-between !important;
    }
    & .label {
       @include displayTextStyle(map.get($thisComponentTokens, topbar, textStyle));
       text-transform: uppercase;
       color: map.get($thisComponentTokens, fontColor);
       margin: map.get($thisComponentTokens, topbar, textMargin);
    }
    & .icon {
        & svg,
        & svg * {
            fill: map.get($thisComponentTokens, fontColor);
        }
}
}

}

@mixin BuildClass(
    $themeName, $specificName, $thisComponentTokens
) {
    $className: dropdown;
	.#{$themeName} .#{$className}#{if($specificName, "."+$specificName,"")}{
        @include BuildStyles($thisComponentTokens); 
	}



/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DO NOT EDIT BELOW THIS LINE
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    













    @include build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens);
}

// Build states from states properties
@mixin build_States($map){
    @if map.has-key($map, states){
        @debug("- states found!");
        @each $stateName, $stateTokens in map.get($map, states){
            &.#{$stateName}{
                @debug("      -> Generated &."+$stateName);
                @include stateStyles($stateTokens);
            }
        }
    }
}

// Build responsiveness from breakpoints properties
@mixin build_Breakpoints_And_States($map, $orientationName){
    // Other properties than breakpoints
        @include build_States($map);
    // Breakpoints
    @if map.has-key($map, breakpoints){
        @debug("- breakpoints found!");
        @each $breakpointName, $breakpointTokens in map.get($map, breakpoints){
            @debug("   - "+$breakpointName);
            // No states
                @include breakpoints.responsive($orientationName, $breakpointName){
                    @include responsiveStyles($breakpointTokens);
                }
            // States within breakpoints
                @include build_States($breakpointTokens);
        }
    }
}

// Build responsiveness from orientations properties (and their breakpoints)
@mixin build_Responsiveness_And_States($map){
    // Other properties than Orientations
        @include build_Breakpoints_And_States($map, allOrientations);
    // Orientations
        @if map.has-key($map, orientations){
            @debug("- orientations found!");
            @each $orientationName, $orientationTokens in map.get($map, orientations){
                @debug("   - "+$orientationName);
                // No breakpoints
                    @include breakpoints.responsive($orientationName, extraSmall){
                        @include responsiveStyles($orientationTokens);
                    }
                // Breakpoints within orientations
                    @include build_Breakpoints_And_States($orientationTokens, $orientationName);
            }
        } @else {
            @debug("- no orientation found.");
        }
}

// Build layouts from layouts properties (and their responsiveness)
@mixin build_Layout_Responsiveness_And_States($themeName, $className, $specificName, $thisComponentTokens){
    @debug("Building  "+$className+":");
    // Other properties than layout
        .#{$themeName} .#{$className}#{if($specificName, "."+$specificName,"")}{
            @include build_Responsiveness_And_States($thisComponentTokens);
        }
    // Layouts
    @if map.has-key($thisComponentTokens, layouts){
        @debug("- layouts found!");
        @each $layoutName, $layoutTokens in map.get($thisComponentTokens, layouts){
            @debug("   - "+$layoutName);
            .#{$themeName} .#{$layoutName} .#{$className}#{if($specificName, "."+$specificName,"")}{
                @debug("      -> Generated ."+$layoutName+" ."+$className+$specificName);
                @include layoutStyles($layoutTokens);
                // Responsiveness within layout
                @include build_Responsiveness_And_States($layoutTokens);
            }
        }
    } @else {
        @debug("- no layout found.");
    }
}