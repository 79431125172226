@use 'sass:list';
@use 'sass:map';
@use 'sass:string';
@use './../utils.scss' as utils;

// Font Faces
// Note that the url is relative to the public folder
@font-face {
    font-family: 'RocheSans';
    src: url('~../Assets/Fonts/RocheSans-Regular.woff') format('woff');
}

@font-face {
    font-family: 'RocheSansLight';
    src: url('~../Assets/Fonts/RocheSans-Light.woff') format('woff');
}

@font-face {
    font-family: 'RocheSansMedium';
    src: url('~../Assets/Fonts/RocheSans-Medium.woff') format('woff');
}

@font-face {
    font-family: 'RocheSansCondensedRegular';
    src: url('~../Assets/Fonts/RocheSansCondensed-Regular.woff') format('woff');
}

@font-face {
    font-family: 'RocheSerifLight';
    src: url('~../Assets/Fonts/RocheSerif-Light.woff') format('woff');
}

$primitiveTokens: (
    borderRadius: (
        normal: 0.75rem,
        medium: 1.25rem,
        large: 2.5rem,
    ),
    borderWidths: (
        small: 0.06rem,
        normal: 0.12rem,
        large: 0.19rem,
    ),
    colors: (
        // BEWARE: Use quotation marks with color keys
        'white': #ffffff,
        'black': #000000,
        RocheGrey1: #544f4f,
        RocheGrey2: #706b69,
        RocheGrey3: #c2bab5,
        RocheGrey5: #f5f5f2,
        RocheBlueDark: #022366,
        RocheBlue: #0b41cd,
        RocheBlueLight: #1482fa,
        RocheBlueLighter: #69affc,
        RocheBlueExtraLight: #bde3ff,
        RocheOrangeDark: #ed4a0d,
        'transparent': transparent,
        'yellow': #ffd500,
    ),
    fontFamilies: (
        Roche,
        Arial,
    ),
    fontWeights: (
        thin: 100,
        extraLight: 200,
        light: 300,
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
        extraBold: 800,
        black: 900,
    ),
    fontSizes: (
        small: 0.75rem,
        normal: 1rem,
        large: 1.125rem,
        extraLarge: 1.25rem,
    ),
    lineHeights: (
        normal: 1.5rem,
        large: 1.75rem,
        extraLarge: 2rem,
    ),
    spacings: (
        none: 0,
        extraTiny: 0.25rem,
        tiny: 0.5rem,
        extraSmall: 0.75rem,
        small: 1rem,
        medium: 1.25rem,
        large: 2rem,
    ),
    transitionDurations: (
        slow: 1s,
        normal: 0.3s,
        fast: 0.1s,
    ),
);

// Generate every font mixins for weights and families
$familyWeights: ();
@each $familyName in map.get($primitiveTokens, fontFamilies) {
    @if $familyName == Roche {
        @each $weightName, $weightValue in map.get($primitiveTokens, fontWeights) {
            $fontFace: RocheSans;
            $defaultFontFace: sans-serif;
            @if $weightValue < 400 {
                $fontFace: RocheSansLight;
            }
            @if $weightValue > 400 {
                $fontFace: RocheSansMedium;
            }
            $newFont: (
                $familyName +
                    utils.capitalize(#{$weightName}):
                    (
                        weight: $weightValue,
                        fontFace: $fontFace + ', ' + $defaultFontFace,
                    )
            );
            $familyWeights: map.deep-merge($familyWeights, $newFont);
        }
    } @else {
        // Add any other families and font weights here
    }
}

// Other inherited primitive toekns
$inheritedPrimitiveTokens: (
    avatar: (
        background:
            linear-gradient(
                0deg,
                map.get($primitiveTokens, colors, RocheBlueDark) 0%,
                map.get($primitiveTokens, colors, RocheBlueLighter) 100%
            ),
    ),
    familyWeights: $familyWeights,
);

// Mergin
$primitiveTokens: map.deep-merge($primitiveTokens, $inheritedPrimitiveTokens);
